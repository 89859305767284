import React from 'react';
import './styles/home.css';

import laptopImage from '../images/workingLaptop.jpg';

function Home() {
  return (
    <div className="hero-section">
      <img src={laptopImage} alt="Laptop" className="hero-image" />
      <div className="hero-text">
        <h1>Smart Shot Technologies</h1>
        <p>
          Smart Shot Technologies (SST) helps organizations modernize, build custom applications, and harness the power of AI to drive innovation and efficiency.
        </p>
      </div>
  </div>
  );
}

export default Home;
