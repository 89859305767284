import React from 'react';
import './styles/services.css';

function Solutions() {
  return (
    <section id="services" className="services">
    <div className="header-section">
      <h2>Our Services</h2>
      <p>SST offers a range of services designed to help businesses thrive in the digital era. From cloud migration to AI-powered applications, we have you covered.</p>
    </div>
    <div className="cards">
      <div className="card">
        <h3>Cloud Solutions</h3>
        <p>Empower your business with scalable and secure cloud solutions tailored to your specific needs. Discover how SST can optimize your cloud strategy for maximum efficiency and performance.</p>
      </div>
      <div className="card">
        <h3>Custom Application Development</h3>
        <p>Unlock the full potential of your business with tailor-made applications. SST specializes in developing custom solutions that align perfectly with your objectives to drive growth.</p>
      </div>
      <div className="card">
        <h3>Artificial Intelligence</h3>
        <p>Develop comprehensive AI strategies tailored to your business needs. From initial planning to building cutting-edge applications that leverage AI, we empower organizations to harness the full potential of artificial intelligence.</p>
      </div>
    </div>
  </section>
  );
}

export default Solutions;
