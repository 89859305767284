import React from 'react';
import './styles/header.css';
import { Link } from 'react-scroll';


import logo from '../images/sst_logo.png';

 

function Header() {
    return (
      <header className="header">
        <div className="logo">
          <img src={logo} alt="SST Logo" />
        </div>
        <div className="title">
        <h1>Smart Shot Technologies</h1>
      </div>
        <nav className="nav-menu">
          <ul>
            <li><Link to="home" smooth={true} duration={500}>Home</Link></li>
            <li><Link to="services" smooth={true} duration={500}>Services</Link></li>
            <li><Link to="contact" smooth={true} duration={500}>Contact</Link></li>
          </ul>
        </nav>
      </header>
    );
  }
  
  export default Header;