import React from 'react';

function Contact() {
  return (
    <section id="contact" className="contact">
        <div>
        <h1>Contact Us</h1>
        <p>Email: chadmcook at gmail.com</p>
        </div>
    </section>  
  );
}

export default Contact;
